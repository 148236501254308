import { useCss, a } from "kremling";
import React from "react";
import { string, object, node, oneOfType } from "prop-types";

const badgeNumberLimit = 99999;
const badgeCountLimit = 99;

export function CpBadge(props) {
  const { children, className, intent = "info", ...rest } = props;
  const scope = useCss(css);
  const isNumber = /^\d+$/.test(children);

  const numWithLimit = () => {
    const limit = intent === "count" ? badgeCountLimit : badgeNumberLimit;
    return children > limit
      ? `${limit.toLocaleString()}+`
      : children.toLocaleString();
  };

  return (
    <div
      {...scope}
      className={a("cp-badge")
        .a(className)
        .a(`cp-badge__${intent.toLowerCase()}`, intent)}
      {...rest}
    >
      {isNumber ? numWithLimit() : children}
    </div>
  );
}

CpBadge.propTypes = {
  children: node.isRequired,
  className: oneOfType([string, object]),
  intent: string,
};

const css = `
  .cp-badge:focus {
    box-shadow: var(--cp-form-focus-state);
    /* Visible in Windows high-contrast themes https://stackoverflow.com/a/52616313/5671022 */
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
  }
  .cp-badge {
    /* remove button styling */
    color: white;
    border: none;
    font: inherit;

    padding: 0.3rem 0.8rem;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    background-clip: padding-box;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
  }
  .cp-badge__info {
    background: var(--cp-color-default-badge-bg);
  }
  .cp-badge__count {
    background: var(--cp-color-well-l3-bg);
    color: var(--cp-color-app-secondary-text);
    border-radius: .4rem;
  }
  .cp-badge__number {
    background: var(--cp-color-notification-badge-bg);
    padding: 1px 4px;
    border-radius: 10px;
    font-size: 9px;
    min-width: 20px;
    height: 19px;
    line-height: 19px;
  }
  .cp-badge__new {
    background: var(--cp-color-new-badge-bg);
  }
  .cp-badge__secondary {
    background: var(--cp-color-well-l2-bg);
    border: 1px solid var(--cp-color-well-border);
    color: var(--cp-color-app-primary-text)
  }
`;
